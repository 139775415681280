import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();  // This gives the current route's pathname

  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to the top of the page
  }, [pathname]);  // This effect will run whenever 'pathname' changes (i.e., when navigating to another route)

  return null;
};

export default ScrollToTop;

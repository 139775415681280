import React, { useState, useEffect } from "react";
import "../Style/Footer.css";
import { Row, Col, Button, Input } from "antd";
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from "../utils/1-removebg-preview.webp";
import { BsRocketFill } from "react-icons/bs";
import { Color } from "antd/es/color-picker";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to scroll to a specific section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Function to handle scroll events
  const handleScroll = () => {
    if (window.scrollY > 300) {
      // Adjust this value as needed
      setIsVisible(true); // Show button when scrolled down
    } else {
      setIsVisible(false); // Hide button when at the top
    }
  };

  // UseEffect hook to add/remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer className="footer-section">
      <div className="container">
        {/* Contact Section */}
        <div className="footer-cta">
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={8} className="single-cta">
              <i className="fas fa-map-marker-alt"></i>
              <div className="cta-text">
                <h4>Find us</h4>
                <span>102,Raj Residency,Maske
                Vasti,Ravet,Pimpri-Chinchwad,Maharashtra,412101</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} className="single-cta">
              <i className="fas fa-phone"></i>
              <div className="cta-text">
                <h4>Call us</h4>
                <span>9420206555</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} className="single-cta">
              <i className="far fa-envelope-open"></i>
              <div className="cta-text">
                <h4>Mail us</h4>
                <span>admin@teople.co.in</span>
              </div>
            </Col>
          </Row>
        </div>

        {/* Main Footer Content */}
        <div className="footer-content">
          <Row gutter={[16, 16]}>
            {/* About Section */}
            <Col xs={24} sm={24} md={8}>
              <div className="footer-widget">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <img src={logo} alt="Logo" />
                  </div>
                </div>
                <div className="footer-social-icon">
                  <span>Follow us</span>
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/profile.php?id=61563310725007&mibextid=rS40aB7S9Ucbxw6v"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://x.com/TeopleT?t=ZU5MIAi1ERJbahXjN11qcg&s=09"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>

                    <a
                      href="https://www.linkedin.com/company/teople-technologies"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/teople_technologies?utm_source=qr&igsh=bGZkYXBoMzhwendm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </Col>

            {/* Useful Links */}
            <Col xs={24} sm={24} md={8}>
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  {[
                    { name: "Home", path: "/" },

                    { name: "Services", path: "/service-section" },
                    { name: "Our Work", path: "/our-work" },

                    { name: "About us", path: "/about" },
                    { name: "Career", path: "/career" },
                    { name: "Our Expertise", path: "/expertise/:tech" },

                    { name: "Contact us", path: "/contact-us" },
                    { name: "Latest News", path: "/blog" },
                  ].map((link, index) => (
                    <li key={index}>
                      <a href={link.path}>{link.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>

            {/* Subscribe Section */}
            <Col xs={24} sm={24} md={8}>
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                <div className="footer-text">
                  <p>
                    Don’t miss to subscribe to our new feeds, kindly fill the
                    form below.
                  </p>
                </div>
                <div className="subscribe-form">
                  <Input
                    type="text"
                    placeholder="Email Address"
                    className="subscribe-input"
                  />
                  <Button
                    type="primary"
                    className="subscribe-button"
                    icon={<i className="fab fa-telegram-plane"></i>}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>
                  Copyright &copy; 2025, All Right Reserved{" "}
                  {/* <a href="https://codepen.io/anupkumar92/"></a> */}
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  {/* <li>
                    <a href="#">Terms</a>
                  </li>
                  <li>
                    <a href="#">Privacy</a>
                  </li>
                  <li>
                    <a href="#">Policy</a>
                  </li> */}
                  <li>
                    <a href="#">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            <BsRocketFill
              className="scroll-to-top-icon"
              style={{ color: "rgba(143, 232, 255, 1)" }}
            />
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;

// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Layout } from "antd";

// import Navbar from "./Componant/Navbar";
// // import OurServices from "./Componant/OurServices";
// import WebDevelopment from "./Componant/pages/services/allServices/WebDevlopment"; // Import the page for this route
// import MobileDevelopment from "./Componant/pages/services/allServices/MobileDevelopment";
// import CloudService from "./Componant/pages/services/allServices/CloudService";
// import ProductDevelopment from "./Componant/pages/services/allServices/ProductDevelopment";
// import HwswIntigration from "./Componant/pages/services/allServices/HwswIntigration";
// import StaffingSolution from "./Componant/pages/services/allServices/StaffingSolution";
// import UiDesign from "./Componant/pages/services/allServices/UiDesign";
// import IotDevelopment from "./Componant/pages/services/allServices/IotDevelopment";

// import MainSection from "./Componant/MainSection";
// import VerticalSlider from "./Componant/pages/VerticalSlider";
// import AboutUs from "./Componant/pages/Aboutus";
// import CareerPage from "./Componant/pages/Career";
// import BlogSection from "./Componant/pages/Blog/BlogSection";
// import Footer from "./Componant/Footer";
// import OurWork from "./Componant/pages/OurWork/OurWork";
// import ExpertiseDetail from "./Componant/pages/ExpertiesPage/ExpertiseDetail";
// import ContactUs from "./Componant/pages/ContactUs/ContactUs";
// import BlogDetail from "./Componant/pages/Blog/BlogDetail";
// import ScrollToTop from "./ScrollToTop/ScrollToTop";

// const { Header, Content } = Layout;

// const App = () => {
//   return (
//     <Router>
//       <ScrollToTop />
//       <Layout>
//         <Navbar />

//         <Routes>
//           {" "}
//           {/* Use Routes instead of Switch */}
//           <Route path="/" element={<MainSection />} />
//           <Route path="/web-development" element={<WebDevelopment />} />
//           <Route path="/mobile-development" element={<MobileDevelopment />} />
//           <Route path="/cloud-services" element={<CloudService />} />
//           <Route path="/product-development" element={<ProductDevelopment />} />
//           <Route path="/hw-sw-integration" element={<HwswIntigration />} />
//           <Route path="/staffing-solution" element={<StaffingSolution />} />
//           <Route path="/ui-design" element={<UiDesign />} />
//           <Route path="/iot-development" element={<IotDevelopment />} />
//           <Route path="/our-work" element={<OurWork />} />
//           <Route path="/blog" element={<BlogSection />} />
//           <Route path="/services" element={<VerticalSlider />} />
//           <Route path="/about" element={<AboutUs />} />
//           <Route path="/career" element={<CareerPage />} />
//           <Route path="/expertise/:tech" element={<ExpertiseDetail />} />
//           <Route path="/contact-us" element={<ContactUs />} />
//           <Route path="/blog-detail/:id" element={<BlogDetail />} />
//         </Routes>
//       </Layout>
//       <Footer />
//     </Router>
//   );
// };

// export default App;
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import Navbar from "./Componant/Navbar";
import Footer from "./Componant/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

// Lazy-loaded components
const MainSection = React.lazy(() => import("./Componant/MainSection"));
const WebDevelopment = React.lazy(() =>
  import("./Componant/pages/services/allServices/WebDevlopment")
);
const MobileDevelopment = React.lazy(() =>
  import("./Componant/pages/services/allServices/MobileDevelopment")
);
const CloudService = React.lazy(() =>
  import("./Componant/pages/services/allServices/CloudService")
);
const ProductDevelopment = React.lazy(() =>
  import("./Componant/pages/services/allServices/ProductDevelopment")
);
const HwswIntigration = React.lazy(() =>
  import("./Componant/pages/services/allServices/HwswIntigration")
);
const StaffingSolution = React.lazy(() =>
  import("./Componant/pages/services/allServices/StaffingSolution")
);
const UiDesign = React.lazy(() =>
  import("./Componant/pages/services/allServices/UiDesign")
);
const IotDevelopment = React.lazy(() =>
  import("./Componant/pages/services/allServices/IotDevelopment")
);
const VerticalSlider = React.lazy(() =>
  import("./Componant/pages/VerticalSlider")
);
const AboutUs = React.lazy(() => import("./Componant/pages/Aboutus"));
const CareerPage = React.lazy(() => import("./Componant/pages/Career"));
const BlogSection = React.lazy(() =>
  import("./Componant/pages/Blog/BlogSection")
);
const OurWork = React.lazy(() =>
  import("./Componant/pages/OurWork/OurWork")
);
const ExpertiseDetail = React.lazy(() =>
  import("./Componant/pages/ExpertiesPage/ExpertiseDetail")
);
const ContactUs = React.lazy(() =>
  import("./Componant/pages/ContactUs/ContactUs")
);
const BlogDetail = React.lazy(() =>
  import("./Componant/pages/Blog/BlogDetail")
);

// Fallback component (e.g., a loading spinner or message)
const Fallback = () => (
  <div style={{ textAlign: "center", padding: "20px" }}>
    <p>Loading...</p>
  </div>
);

const { Header, Content } = Layout;

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Navbar />
        <Content>
          <Suspense fallback={<Fallback />}>
            <Routes>
              <Route path="/" element={<MainSection />} />
              <Route path="/web-development" element={<WebDevelopment />} />
              <Route
                path="/mobile-development"
                element={<MobileDevelopment />}
              />
              <Route path="/cloud-services" element={<CloudService />} />
              <Route
                path="/product-development"
                element={<ProductDevelopment />}
              />
              <Route path="/hw-sw-integration" element={<HwswIntigration />} />
              <Route path="/staffing-solution" element={<StaffingSolution />} />
              <Route path="/ui-design" element={<UiDesign />} />
              <Route path="/iot-development" element={<IotDevelopment />} />
              <Route path="/our-work" element={<OurWork />} />
              <Route path="/blog" element={<BlogSection />} />
              <Route path="/services" element={<VerticalSlider />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/career" element={<CareerPage />} />
              <Route path="/expertise/:tech" element={<ExpertiseDetail />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/blog-detail/:id" element={<BlogDetail />} />
            </Routes>
          </Suspense>
        </Content>
      </Layout>
      <Footer />
    </Router>
  );
};

export default App;
import React, { useState, useEffect, useRef } from "react";
import { Drawer, Button, Collapse } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import logo from "../utils/Logo/Teople_Technologies-final.webp"; // Update with your logo path
import "../Style/Navbar.css"; // Update with your stylesheet
import { Layout } from "antd";

const { Header, Content } = Layout;
const { Panel } = Collapse;

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false); // For services dropdown
  const [expertiseDropdownVisible, setExpertiseDropdownVisible] =
    useState(false); // For expertise dropdown
  const [selectedService, setSelectedService] = useState(null); // State for selected service
  const [navbarVisible, setNavbarVisible] = useState(true);
  const lastScrollY = useRef(window.scrollY);

  // Handle scrolling
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY.current && currentScrollY > 50) {
        setNavbarVisible(false); // Hide on scroll down
      } else {
        setNavbarVisible(true); // Show on scroll up
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle screen resizing
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1000);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Expertise list
  const expertiseList = [
    { name: "Java", path: "/expertise/java", icon: "devicon:java" },
    {
      name: "Spring Boot",
      path: "/expertise/spring-boot",
      icon: "devicon:spring",
    },
    { name: "NodeJs", path: "/expertise/node-js", icon: "devicon:nodejs" },
    { name: "Python", path: "/expertise/python", icon: "devicon:python" },

    {
      name: "JavaScript",
      path: "/expertise/java-script",
      icon: "fa-brands:js-square",
      color: "#F7DF1E",
    },
    { name: "ReactJs", path: "/expertise/react-js", icon: "devicon:react" },
    { name: "Angular", path: "/expertise/angular", icon: "devicon:angular" },
    { name: "PWA", path: "/expertise/pwa", icon: "devicon:firebase" },
    {
      name: "AWS",
      path: "/expertise/aws",
      icon: "fa-brands:aws",
      color: "#FF9900",
    },
    {
      name: "MySQL",
      path: "/expertise/my-sql",
      icon: "devicon:mysql",
      color: "#4479A1",
    },
    {
      name: "GitLab",
      path: "/expertise/git-lab",
      icon: "fa-brands:gitlab",
      color: "#FC6D26",
    },
    {
      name: "Tailwind",
      path: "/expertise/tailwind",
      icon: "devicon:tailwindcss",
      color: "#38B2AC",
    },
    {
      name: "Docker",
      path: "/expertise/docker",
      icon: "devicon:docker",
      color: "#2496ED",
    },
    {
      name: "Jenkins",
      path: "/expertise/jenkins",
      icon: "devicon:jenkins",
      color: "#D24939",
    },
    {
      name: "Vue.js",
      path: "/expertise/vuejs",
      icon: "devicon:vuejs",
      color: "#42B883",
    },
    {
      name: "Jira",
      path: "/expertise/jira",
      icon: "devicon:jira",
      color: "#0052CC",
    },
  ];

  // Services list
  const servicesList = [
    {
      name: "Staffing Solution",
      path: "/staffing-solution",
      icon: "mdi:account-group",
      color: "#38B2AC",
    },
    {
      name: "Web Development",
      path: "/web-development",
      icon: "mdi:web",
      color: "#0A5EB0",
    },
    {
      name: "Mobile Development",
      path: "/mobile-development",
      icon: "mdi:cellphone",
      color: "#F7DF1E",
    },
    {
      name: "Cloud Services",
      path: "/cloud-services",
      icon: "mdi:cloud-outline",
      color: "#4CC9FE",
    },
    {
      name: "Product Development",
      path: "/product-development",
      icon: "mdi:rocket-launch-outline",
      color: "#F93827",
    },
    {
      name: "Hw-sw integration",
      path: "/hw-sw-integration",
      icon: "mdi:chip",
      color: "#185519",
    },

    {
      name: "UI Design",
      path: "/ui-design",
      icon: "devicon:react",
    },
    {
      name: "Iot Development",
      path: "/iot-development",
      icon: "mdi:antenna",
      color: "#EF5A6F",
    },
  ];

  // Toggle "Our Services" dropdown
  const toggleServicesDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  // Toggle "Our Expertise" dropdown
  const toggleExpertiseDropdown = () => {
    setExpertiseDropdownVisible((prev) => !prev);
  };

  // Handle "Our Services" item click
  const handleServiceClick = (service) => {
    setSelectedService(service); // Set selected service data
    setDropdownVisible(false); // Close dropdown
    setDrawerVisible(false); // Close drawer if open
    navigate(service.path, { state: { service } }); // Navigate with service state
  };

  // Handle "Our Expertise" item click
  const handleLinkClick = (expertise) => {
    setExpertiseDropdownVisible(false); // Close expertise dropdown
    setDrawerVisible(false); // Close drawer if open
    navigate(expertise.path); // Navigate to expertise path
  };

  // Show the drawer
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  // Close the drawer
  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  // Handle screen resizing for responsive design
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000); // Adjust breakpoint as needed
    };

    handleResize(); // Call on mount
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header className={`navbarbg ${navbarVisible ? "visible" : "hidden"}`}>
        <Link to="/" style={{ display: "flex", alignItems: "center" }}>
          <img className="teople-logo" src={logo} alt="Logo" />
        </Link>

        {/* Desktop Navbar */}
        {!isMobile && (
          <div
            className="desktop-navbar"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              gap: "2em",
            }}
          >
            <Link
              className={
                location.pathname === "/about" ? "nav-link active" : "nav-link"
              }
              to="/about"
            >
              About Us
            </Link>
            <Link
              className={
                location.pathname === "/our-work"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/our-work"
            >
              Our Work
            </Link>
            <div className="nav-dropdown">
              <Link
                className={
                  servicesList.some(
                    (service) => location.pathname === service.path
                  )
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={toggleServicesDropdown}
              >
                Our Services
              </Link>
              <div className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}>
                {servicesList.map((service, index) => (
                  <Link
                    key={index}
                    className="dropdown-item"
                    to={service.path}
                    onClick={() => handleServiceClick(service)}
                  >
                    <Icon
                      icon={service.icon}
                      style={{
                        marginRight: "8px",
                        color: service.color || "inherit",
                      }}
                    />
                    {service.name}
                  </Link>
                ))}
              </div>
            </div>

            <div className="nav-dropdown">
              <Link
                className={
                  expertiseList.some(
                    (service) => location.pathname === service.path
                  )
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={toggleExpertiseDropdown}
              >
                Our Expertise
              </Link>
              <div
                className={`dropdown-menu ${
                  expertiseDropdownVisible ? "show" : ""
                }`}
              >
                {expertiseList.map((expertise, index) => (
                  <Link
                    key={index}
                    className="dropdown-item"
                    to={expertise.path}
                    onClick={() => handleLinkClick(expertise)}
                  >
                    <Icon
                      icon={expertise.icon}
                      style={{
                        marginRight: "8px",
                        color: expertise.color || "inherit",
                      }}
                    />
                    {expertise.name}
                  </Link>
                ))}
              </div>
            </div>

            <Link
              className={
                location.pathname === "/blog" ? "nav-link active" : "nav-link"
              }
              to="/blog"
            >
              Blog
            </Link>
            <Link
              className={
                location.pathname === "/career" ? "nav-link active" : "nav-link"
              }
              to="/career"
            >
              Career
            </Link>
            <Link
              className={
                location.pathname === "/contact-us"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/contact-us"
            >
              Contact Us
            </Link>
          </div>
        )}

        {/* Mobile Menu Button */}
        {isMobile && (
          <Button
            className="mobile-menu-button"
            type="primary"
            icon={<MenuOutlined />}
            onClick={showDrawer}
          />
        )}
      </Header>

      {/* Mobile Drawer */}
      <Drawer
        title={<h2 className="drawer-title">Teople Tech</h2>}
        placement="left"
        onClose={closeDrawer}
        open={drawerVisible}
        width={300}
      >
        <Link
          className={
            location.pathname === "/about" ? "nav-link active" : "drawer-link"
          }
          to="/about"
          onClick={closeDrawer}
        >
          About Us
        </Link>

        {/* Our Services in Drawer */}
        <div className="drawer-dropdown">
          <Link className="drawer-link" onClick={toggleServicesDropdown}>
            Our Services
          </Link>
          <div
            className={`drawer-dropdown-menu ${dropdownVisible ? "show" : ""}`}
          >
            {servicesList.map((service, index) => (
              <Link
                key={index}
                className="drawer-dropdown-item"
                to={service.path}
                onClick={() => handleServiceClick(service)}
              >
                <Icon
                  icon={service.icon}
                  style={{ color: service.color || "inherit" }}
                />
                {service.name}
              </Link>
            ))}
          </div>
        </div>

        {/* Our Expertise in Drawer */}
        <div className="drawer-dropdown">
          <Link className="drawer-link" onClick={toggleExpertiseDropdown}>
            Our Expertise
          </Link>
          <div
            className={`drawer-dropdown-menu ${
              expertiseDropdownVisible ? "show" : ""
            }`}
          >
            {expertiseList.map((expertise, index) => (
              <Link
                key={index}
                className="drawer-dropdown-item"
                to={expertise.path}
                onClick={() => handleLinkClick(expertise)}
              >
                <Icon
                  icon={expertise.icon}
                  className="drawer-dropdown-item-icon"
                  style={{ color: expertise.color || "inherit" }}
                />
                {expertise.name}
              </Link>
            ))}
          </div>
        </div>

        <Link className="drawer-link" to="/our-work" onClick={closeDrawer}>
          Our Work
        </Link>
        <Link className="drawer-link" to="/blog" onClick={closeDrawer}>
          Blog
        </Link>
        <Link className="drawer-link" to="/career" onClick={closeDrawer}>
          Career
        </Link>
        <Link className="drawer-link" to="/contact-us" onClick={closeDrawer}>
          Contact Us
        </Link>
      </Drawer>

      <Content style={{ paddingTop: "70px" }}>
        {/* Display Selected Service Details */}
        {selectedService && <div className="service-details"></div>}
      </Content>
    </>
  );
};

export default Navbar;
